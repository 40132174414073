.stepper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
}
