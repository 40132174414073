#search-bar{
  margin-bottom: 25px; 
}

#study-search-bar {
  .field {
    background-color: #c8c8c8;
    border-radius: 8px;
    padding: 10px;
    position: relative;

    i {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 16px;
      display: flex;
      align-items: center;
    }

    input {
      width: 100%;
      margin: 0;
      padding: 16px;
      padding-left: 52px;
      font-size: inherit;
      line-height: 1;
      font-family: inherit;
      color: inherit;
      background-color: #fff;
      border: 1px solid #c8c8c8;
      appearance: none;
      outline: 0;
      border-radius: 6px;
    }
  }
}