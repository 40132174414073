#page-inicio {
  .page-body-content {
    position: relative;

    &:before {
      content: '';
      background: linear-gradient(90deg, #4466f2 60%, #46ffc9 100%);
      height: 120px;
      position: absolute;
      display: block;
      top: 0px;
      left: 0px;
      right: 0px;
    }
  }
}
