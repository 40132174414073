#panelist-list {
  margin-bottom: 25px;

  h2 {
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 20px;
  }

  & > div {
    @media (min-width: 768px) {
      overflow: auto;
      height: calc(100vh - 60px - 210px - 55px - 50px);
    }

    ul {
      overflow: hidden;
      padding: 0px;
      margin: 0px;

      li {
        list-style: none;

        & > * {
          margin: 0;
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
