#filters {
  position: sticky;
  top: 15px;

  .card {
    .card-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .total-filtered {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .badge {
          font-weight: normal;
          padding: 0 13px;
          font-size: 13px;
          font-weight: 500;
          color: #2e6379;
          line-height: 26px;
          background-color: #46ffc9;
          border-radius: 13px;
        }
      }
    }
    .card-body {
      .nav {
        .nav-item {
          width: 100%;

          & > a {
            display: flex;
            padding-left: 20px;
            align-items: center;
            color: #898989;
            line-height: 39px;
            text-transform: uppercase;
            border-radius: 3px;

            &.active,
            &:hover  {
              background-color: #f6f7fb;
              text-decoration: none;
            }
          }

          .main-title {
            color: #4466f2;
            font-weight: 600;
            margin-bottom: 5px;
            display: block;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
