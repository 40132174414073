$colors: red, orange, yellow, green, blue, purple;
$repeat: 20; // How often you want the pattern to repeat.
// Warning: a higher number outputs more CSS.

.user-profile {
  .card {
    &.hovercard {
      margin-top: 100px;
    }
  }
  .user-image {
    .avatar {
      display: block;
      width: auto;
      height: auto;
      border-radius: 0;
      background: none;

      max-width: 125px;
      height: 125px;
      margin: -100px auto 0px;
      border-radius: 100%;
      background-color: nth($colors, random(length($colors)));
      display: flex;
      font-size: 50px;

      img {
        width: 125px !important;
        height: 125px !important;
      }
    }
  }
}
