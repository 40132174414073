.page-wrapper {
  .page-main-header {
    .main-header-right {
      padding: 0px;
      justify-content: flex-end;

      .nav-right > ul.nav-menus {
        top: 0;
        position: relative;
        z-index: 1;
        background-color: transparent;
        transition: none;
        box-shadow: none;
        width: 100%;
        left: auto;
        transform: none;
        opacity: 1;
        visibility: visible;
        padding: 0px 15px;

        @media (min-width: 768px) {
        }
      }
    }
  }
}
