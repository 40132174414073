.disc {
  font-size: 12px;
  width: 21px;
  height: 21px;
  margin-right: 16px;
  border-radius: 100%;
  color: white;
  background-color: #c4c4c4;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  &.current {
    background-color: #6c5ce7;
  }
}

.step {
  flex-shrink: 0;
  line-height: 15px;
  margin: 0px;
  padding: 0px;
  border: 0px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 56px;
  padding-right: 32px;
  background: transparent;
  outline: 0px;

  &:not(:last-child) {
    margin-right: 20px;
  }

  &:focus,
  &:active,
  &:hover {
    outline: 0;
  }

  &.current {
    outline: 0;
    color: #6c5ce7;

    &:after {
      content: '';
      height: 2px;
      background-color: #6c5ce7;
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0px;
      left: 0px;
    }
  }
  @media (min-width: 992px) {
    font-size: 16px;
    line-height: 19px;
  }
}
