.authentication-main {
  .card {
    width: 380px;
  }
}

.fake-form-group {
  .label {
    display: block;
    color: #999;
    line-height: 21px;
    padding-bottom: 7px;
    font-size: 14px;
  }

  .input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    color: #495057;
    background-color: #e9ecef;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
}
