.panelist-information {
  overflow: hidden;

  .card-header {
    position: sticky;
    z-index: 10;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px -7px 10px 4px rgba(0, 0, 0, 0.4);
    border: 0;
    color: white;
    top: 0px;

    @media (min-width: 992px) {
      top: 0px;
      border-bottom: 1px solid #f6f7fb;
    }

    .card-title {
      color: black;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        border: 0;
        padding: 0 5px;
        font-size: 12px;
      }
    }
  }
}
