.panelist-card {
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;
  background-color: #fff;
  border: 1px solid #c8c8c8;
  border-radius: 8px;
  min-height: 100px;
  padding: 15px;
  margin-bottom: -1px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  position: relative;
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 15px 40px 15px 15px;
  }

  &.study-in-progress {
    background-color: #fffcd2;
    border: 0;
  }

  .study-in-progress {
    font-weight: bold;
  }

  .picture {
    position: relative;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    padding: 2px;
    background-color: #c2c3c4;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      margin-bottom: 0px;
    }

    span {
      display: block;
      border-radius: 100%;
      overflow: hidden;
      width: 100%;
      height: 100%;
      border: 2px solid white;

      .avatar {
        width: 50px;
        height: 50px;
      }
    }
  }

  .information {
    flex-grow: 1;
    margin-left: 0px;

    @media (min-width: 768px) {
      margin-left: 15px;
    }

    & > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      font-size: 14px;
      line-height: 22px;
      color: #757575;

      & > a {
        color: #757575;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        height: 22px;
        margin-right: 5px;
      }
    }
  }

  .go-to-panelist {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .status-inactive,
  .status-active,
  .status-study-in-progress {
    border-radius: 100%;
    width: 14px;
    height: 14px;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
}
