$primary-color: #4466f2;

body {
  .page-wrapper {
    .page-body-wrapper {
      display: flex;

      .page-sidebar {
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 4px 0px 8px;

        position: fixed;
        top: 0;
        left: -70px;

        width: auto;
        height: 100vh;
        width: 60px;

        @media (min-width: 768px) {
          left: auto;
        }

        &.open {
          margin-left: 70px;
          width: 200px;

          @media (min-width: 768px) {
            margin-left: 0px;
          }

          ul.sidebar-menu {
            align-items: flex-start;

            li {
              &:first-child {
                @media (max-width: 767px) {
                  left: 20px;
                  z-index: 1;
                }
              }

              &:not(:first-child) {
                width: 100%;
              }

              & > * {
                width: 100%;
                justify-content: flex-start;
                padding: 0px 10px;

                svg {
                  transition: stroke 0.2s ease-in-out 0s;
                  stroke: $primary-color;
                }

                span {
                  margin-left: 0px;
                  z-index: 10;
                  visibility: visible;
                  opacity: 1;
                  position: static;
                  left: auto;
                  background-color: transparent;
                  color: $primary-color;
                }

                &:hover {
                  background-color: #e5e5ef;
                  padding: 0px 10px;

                  svg {
                    stroke: $primary-color;
                  }
                }
              }
            }
          }

          .sidebar {
            overflow: hidden;
          }

          .sidebar-header {
            background-color: transparent;
            padding: 10px 30px 10px 10px;
          }
        }

        ul.sidebar-menu {
          list-style: none;
          display: flex;
          margin-bottom: 8px;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding: 10px;

          li {
            padding: 0px;
            margin-bottom: 10px;
            outline: 0;

            &:first-child {
              @media (max-width: 767px) {
                position: absolute;
                top: 13px;
                left: calc(100% + 20px);
                margin-bottom: 0;
                border: 2px solid #4466f2;
                border-radius: 12px;
                overflow: hidden;
                z-index: -1;

                & > button {
                  width: 30px;
                  height: 30px;
                  padding: 5px;
                }
              }
            }

            & > * {
              border: 0px;
              margin: 0px;
              padding: 0px;
              width: 40px;
              height: 40px;
              padding: 0px 10px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              text-align: left;
              background-color: transparent;
              position: relative;
              outline: 0;

              svg {
                transition: all 0.2s ease-in-out 0s;
                stroke: $primary-color;
              }

              span {
                margin-left: 16px;
                white-space: nowrap;
                font-size: 14px;
                color: rgb(255, 255, 255);
                padding: 0px 12px;
                line-height: 40px;
                opacity: 0;
                visibility: hidden;
                border-radius: 8px;
                background-color: $primary-color;
                position: absolute;
                left: 100%;
              }

              &:hover {
                padding: 0px;
                background-color: $primary-color;

                span {
                  z-index: 10;
                  visibility: visible;
                  opacity: 1;
                }

                svg {
                  stroke: white;
                }
              }
            }
          }
        }

        .sidebar {
          height: 100vh;
          overflow: visible;

          @media (min-width: 768px) {
            height: calc(100vh - 80px);
          }
        }

        .sidebar-header {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 10px;
          background-color: white;
          height: 60px;

          @media (min-width: 768px) {
            height: 80px;
          }

          .sidebar-menu-toggle {
            outline: none;
            cursor: pointer;
            border: 0px;
            margin: 0px;
            padding: 0px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;

            svg {
              stroke: $primary-color;
            }
          }
        }
      }
    }
  }
}
