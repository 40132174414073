#studies-list {
  margin-bottom: 25px;
  overflow: hidden;

  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 20px;

    a {
      font-size: 14px;
      line-height: 16px;
    }
  }

  #statistics-studies-list {
    @media (min-width: 768px) {
      height: calc(100vh - 225px - 170px - 52px - 50px);
      overflow: auto;
    }
  }

  & > div {
    ul {
      overflow: hidden;
      padding: 0px;
      margin: 0px;

      li {
        list-style: none;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
}
