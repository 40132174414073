.pagination-button {
  position: relative;
  border: 0px;
  padding: 0px;
  margin: 0px;
  background-color: white;
  color: rgba(0, 0, 24, 0.48);
  font-size: 14px;
  width: 24px;
  line-height: 24px;
  border-radius: 50%;
  outline: 0;
  display: flex;
  align-items: center;
	justify-content: center;
  cursor: pointer;
	
	&:hover {
		background: rgba(0, 0, 24, 0.08);
		border-radius: 50px;
	}
}

.pagination-active {
  content: '';
  display: block;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  background-color: #4466f2;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  top: 0;
	left: 0;
}

.pagination-disabled {
	&:disabled,
	&[disabled] {
		background: #fbfbfb;

		i:before {
			background-color: #e3e3e3;
		}
	}
}


div.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 92px;
}


div.pagination-list {
  margin: 0px;
  padding: 0px 4px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  background-color: white;
  height: 24px;
}

button.page-link {
  @extend .pagination-button;

  &.active {
    width: 30px;
    height: 30px;	
    position: relative;
    
    span {
      position: relative;
      z-index: 2;
      font-weight: 600;
      font-size: 16px;
      color: white;
    }

    &:after {
      @extend .pagination-active
    }
  }
}

button.prev-page,
button.next-page {
  height: 24px;
  margin-right: 16px;
  border-radius: 50%;
  @extend .pagination-button;
  @extend .pagination-disabled;
}

div.etc {
  font-size: 14px;
  line-height: 24px;
  width: 24px;
  height: 24px;
  text-align: center;
}
