#total-panelists {
  margin-top: 20px;

  .circle {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4466f2;
  }

  .totals {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    span {
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 24, 0.48);
    }

    span:first-child {
      font-size: 22px;
      line-height: normal;
      color: #313131;
      margin-bottom: 5px;

      .counter {
        font-weight: bold;
      }
    }
  }

  .card-body {
    & > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
