#contact-data {
  position: sticky;
  top: 15px;

  .card-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .picture {
      margin-right: 10px;
      position: relative;
      width: 55px;
      height: 55px;
      border-radius: 100%;
      padding: 2px;
      background-color: #c2c3c4;

      span {
        display: block;
        border-radius: 100%;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border: 2px solid white;

        .avatar {
          width: 47px;
          height: 47px;
        }
      }
    }

    .panelist-name {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      .avatar {
        font-size: 18px;
        margin-right: 10px;
      }
    }

    .study-in-progress,
    .panelist-age {
      font-size: 12px;
      font-weight: normal;
      text-transform: none;
    }

    .study-in-progress {
      margin-top: 5px;
      padding: 2px 5px;
      background-color: #fffcd2;
      border-radius: 4px;
    }

    .status-inactive,
    .status-active,
    .status-study-in-progress {
      border-radius: 100%;
      width: 14px;
      height: 14px;
      position: absolute;
      bottom: 0px;
      right: 0px;
    }
  }
}
