#change-panelist-state{
  position: relative;

  .form-group {
    width: 100%;
  }
  
  .panelist-state-spinner{
    position: absolute;
    top: 0%;
    left: 0%;
    background-color: rgba(255,255,255,.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}