.error-field {
  border-color: red !important;
  color: red !important;

  &::placeholder {
    color: red !important;
  }
}


.input-feedback {
  font-family: work-Sans, sans-serif;
  font-size: 12px;
  color: red;
}

form {
  h5:not(:first-child) {
    margin-top: 50px;
  }
}
