.project-box {
  position: relative;
  padding: 30px 15px;
  margin-bottom: 25px;

  @media (min-width: 768px) {
    padding: 30px;
  }

  .actions {
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255,255,255,.3);
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .1s ease-in-out;
  }

  .study-zones {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    min-height: 22px;
  }

  &:hover {
    .actions {
      opacity: 1;
    }
  }
}

button.duplicate-study {
  margin-top: 10px;
  border: 0;

  &:hover {
    background: none!important;
    color: #007bff;
    text-decoration: underline;
  }
}