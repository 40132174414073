body {
  .page-wrapper {
    .page-body-wrapper {
      display: block !important;

      @media (min-width: 768px) {
        display: flex !important;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
      }

      .page-main-header {
        margin: 0px;
        width: 100%;
        max-width: 100%;
      }

      .page-body {
        height: 100vh;
        overflow: auto;
        flex-grow: 1;
        margin: 0;
        padding: 60px 0px 0px 0px !important;

        @media (min-width: 768px) {
          padding: 60px 0px 0px 60px !important;
        }

        @media (min-width: 992px) {
          padding: 80px 0px 0px 60px !important;
        }

        .page-body-content {
          background-color: #edf1f7;
          min-height: calc(100% - 53px);
        }

        .footer {
          margin: 0px;
        }
      }
    }
  }
}
