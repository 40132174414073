.go-to-study {
  &.simplified-study-card {
    position: relative;
    padding: 0px;
    border: 0px;
    margin: 0px;
    outline: 0px;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    text-align: left;

    .project-box {
      padding: 15px;
    }
  }
}
