.checkbox-option {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .input[type='checkbox'] {
    height: 19px;
    width: 19px;
  }

  label {
    margin-bottom: 0px;
    line-height: 19px;
  }
}
