.avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: #4466f2;
  color: white;
}
